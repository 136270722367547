<template>
  <div class="quotas-overview">
    <h3 class="mb-6">
      {{ $t('overview') }}
    </h3>
    <div v-if="!loading">
      <CyQuotasResourceUtilisationCard
        v-for="resource in getQuotasOverview"
        :key="`utilisation-card-${resource.type}`"
        :resource="resource"
        class="mb-8"
        @viewDetails="setTableProps($event)"/>
    </div>
    <div
      v-else
      class="d-flex justify-center pt-4">
      <v-progress-circular
        indeterminate
        color="secondary"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CyQuotasResourceUtilisationCard from '@/components/CyQuotasResourceUtilisationCard.vue'

export default {
  name: 'CyQuotasOverview',
  components: {
    CyQuotasResourceUtilisationCard,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters('layout', [
      'getDataTableProps',
    ]),
    ...mapGetters('organization/quota', [
      'getQuotasOverview',
    ]),
  },
  async created () {
    this.loading = true
    await this.GET_OVERVIEW()
    this.loading = false
  },
  methods: {
    ...mapActions('organization/quota', [
      'GET_OVERVIEW',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_PROPS',
    ]),
    setTableProps (resourceType) {
      this.SET_DATA_TABLE_PROPS({
        name: 'quotas',
        props: {
          ...this.getDataTableProps('quotas'),
          filters: {
            'type[in]': resourceType,
          },
          sortBy: ['usage'],
          sortDesc: [true],
          groupBy: [],
        },
      })
    },
  },
  i18n: {
    messages: {
      en: {
        overview: '@:routes.environmentOverview',
      },
      es: {
        overview: '@:routes.environmentOverview',
      },
      fr: {
        overview: `@:routes.environmentOverview`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.quotas-overview {
  width: 355px;
}
</style>
